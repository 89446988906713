'use strict';

(function(){

    var body = document.getElementById('main-container') || document.documentElement;
    var menu = document.getElementById('mainNav') || document.getElementById('globalNav');
    var hamburger = document.getElementById('hamburger');

    function toggleMenu() {
        body.scrollTop = 0;
        
        if (menu.classList.contains('open')) {
            menu.classList.remove('open');
            hamburger.classList.remove('open');
            body.classList.remove('lock');
        } else {
            menu.classList.add('open');
            hamburger.classList.add('open');
            body.classList.add('lock');
        }
    }

    hamburger && hamburger.addEventListener('click', toggleMenu, true);

    menu && menu.getElementsByClassName('primaryNavItem').forEach(function(ni) {
        ni.addEventListener('click', function() {
            var parent = ni.parentElement;
            if(parent.classList.contains('open')) {
                parent.classList.remove('open');
                parent.blur();
            } else {
                parent.classList.add('open');
                parent.blur();
            }
        })
    });
})();